import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-ideal-para',
  templateUrl: './ideal-para.component.html',
  styleUrls: ['./ideal-para.component.scss'],
})
export class IdealParaComponent implements OnInit {
  itens = [
    {
      title: 'Pessoas com comorbidades',
      text:
        'Para quem tem Asma, Hipertensão arterial, DPOC, Insuficiência cardíaca, Covid-19',
      image: 'assets/images/ideal-para-1.png',
    },
    {
      title: 'Idosos',
      text:
        'Para quem tem 60+ anos e quer garantir um cuidado especial',
      image: 'assets/images/ideal-para-2.png',
    },
    {
      title: 'Em tratamentos',
      text:
        'Atenção especial para os momentos de tratamento',
      image: 'assets/images/ideal-para-3.png',
    },
    {
      title: 'Pós-cirúgicos',
      text:
        'Para uma recuperação ainda tranquila, com um suporte extra.',
      image: 'assets/images/ideal-para-4.png',
    },
  ];
  height: number;
  width: number;

  constructor() {}

  ngOnInit(): void {
    this.detectScreenSize();
  }

  @HostListener('window:resize', [])
  public onResize() {
    this.detectScreenSize();
  }

  private detectScreenSize() {
    this.height = window.innerHeight;
    this.width = window.innerWidth;
  }
}
