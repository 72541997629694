<app-header></app-header>
<app-apresentacao></app-apresentacao>
<app-conheca></app-conheca>
<app-cuidado></app-cuidado>
<app-humanizacao></app-humanizacao>
<app-como-funciona></app-como-funciona>
<!-- <app-orcamento></app-orcamento> -->
<!-- <app-planos></app-planos> -->
<app-ideal-para></app-ideal-para>
<app-modal-termos></app-modal-termos>
<!-- <app-beneficios></app-beneficios>
<app-preco></app-preco>
<app-valores></app-valores>
<app-para-quem></app-para-quem>
<app-legislacao></app-legislacao>
<app-materiais></app-materiais>
<app-perguntas></app-perguntas> -->
<app-canais></app-canais>
<app-footer></app-footer>
