import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-canais',
  templateUrl: './canais.component.html',
  styleUrls: ['./canais.component.scss']
})
export class CanaisComponent implements OnInit {
  
  private _whatsappMessageToSend?: string;

  private _whatsappContactPhone?: string;

  constructor() {
    this._whatsappContactPhone = environment.whatsappContactPhone;
    this._whatsappMessageToSend = 'Ol%C3%A1,%20gostaria%20de%20mais%20informações%20sobre%20o%20Sa%C3%BAde%20Cinco.';
  }

  
  public get whatsappContactPhone(): string {
    return this._whatsappContactPhone;
  }

  public get whatsappMessageToSend(): string {
    return this._whatsappMessageToSend;
  }
  

  ngOnInit(): void {
  }

}
