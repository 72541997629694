<footer id="footer" class="footer">
  <div class="container">
    <div class="logos">
      <a
        href="https://inovahu.com"
        title="Visitar o site da Hu. Innovation"
        class="inovahu"
        target="_blank"
      >
        <img src="assets/images/logo-hu.svg" alt="Hu. Innovation" />
      </a>

      <img src="assets/images/logo-cinco.svg" alt="Cinco" />
    </div>
    <div class="social">
      <ul>
        <li>
          <a
            href="https://www.facebook.com/saudecinco"
            title="Acompanhe-nos no Facebook"
            target="_blank"
          >
            <i class="fa fa-facebook" aria-hidden="true"></i>
          </a>
        </li>
        <li>
          <a
            href="https://www.instagram.com/saudecinco"
            title="Siga-nos no Instagram"
            target="_blank"
          >
            <i class="fa fa-instagram" aria-hidden="true"></i>
          </a>
        </li>
      </ul>
    </div>
  </div>
</footer>
