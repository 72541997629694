import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { BandHandbookComponent } from './pages/band-handbook/band-handbook.component';
import { HomeComponent } from './pages/home/home.component';
import { TermsUseComponent } from './pages/terms-use/terms-use.component';
// import { ContatoComponent } from './pages/contato/contato.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
// import { ConsentTermComponent } from './pages/consent-term/consent-term.component';

const routes: Routes = [
  { path: '', redirectTo: 'inicio', pathMatch: 'full' },
  { path: 'inicio', component: HomeComponent },
  { path: 'termos-e-privacidade', component: TermsUseComponent },
  { path: 'suporte/manual', component: BandHandbookComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  // { path: 'consent-term', component: ConsentTermComponent },
  // { path: 'contato', component: ContatoComponent , pathMatch: 'full' },
];

const routerOptions: ExtraOptions = {
  useHash: false,
  anchorScrolling: 'enabled',
  onSameUrlNavigation: 'reload',
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
  declarations: [],
})
export class AppRoutingModule {}
