<section id="como-funciona" class="como-funciona">
  <div class="container text-center">
    <h2>Como funciona?</h2>
    <div class="row" *ngIf="width >= 992">
      <div class="col col-md-12 col-lg-4 numeros">
        <div class="numero">
          <span>
            1
          </span>
        </div>
      </div>

      <div class="col col-md-12 col-lg-4 numeros">
        <div class="numero">
          <span>
            2
          </span>
        </div>
      </div>

      <div class="col col-md-12 col-lg-4 numeros">
        <div class="numero">
          <span>
            3
          </span>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="width >= 992">
      <div
        class="etapa col-md-12 col-lg-4"
        *ngFor="let etapa of etapas; let i = index"
      >
        <h5>{{ etapa.title }}</h5>

        <p>{{ etapa.text }}</p>
        <img src="{{ etapa.image }}" class="img-fluid" />
      </div>
    </div>

    <div class="row" *ngIf="width < 992">
      <div class="col">
        <div id="carouselIndicatorComoFunciona" class="carousel slide" data-ride="carousel">
          <ol class="carousel-indicators">
            <li
              data-target="#carouselIndicatorComoFunciona"
              data-slide-to="0"
              class="active"
            ></li>
            <li data-target="#carouselIndicatorComoFunciona" data-slide-to="1"></li>
            <li data-target="#carouselIndicatorComoFunciona" data-slide-to="2"></li>
          </ol>
          <div class="carousel-inner">
            <div
              class="carousel-item"
              *ngFor="let etapa of etapas; let isFirst = first"
              [ngClass]="{ active: isFirst }"
            >
              <div class="numero">
                <span>
                  {{ etapa.stage }}
                </span>
              </div>

              <h5>{{ etapa.title }}</h5>

              <p>{{ etapa.text }}</p>

              <img src="{{ etapa.image }}" class="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
