<section id="apresentacao" class="apresentacao">
  <div class="container-fluid">
    <div class="container">
        <div class="col">
          <span *ngIf="width >= 480">Cuidado inteligente e conectado</span>
          <h1>Bem-estar físico e emocional para quem você ama</h1>
          <p>
            Uma solução inovadora de cuidado digital, criada especialmente para quem vive com alguma condição de saúde. Junte-se a nós e abrace uma vida repleta de possibilidades.
          </p>
          <div class="botoes">
            <a class="btn-outline-lg btn-outline-lg-white page-scroll" href="#conheca">Saiba mais</a>
          </div>
        </div>
    </div>
  </div>
</section>
