<section id="cuidado" class="cuidado">
  <div class="container text-center">
    <h2>O cuidado certo quando você mais precisa</h2>

    <p>O nosso compromisso com a empatia e a qualidade nos faz colocar as pessoas no centro do cuidado. Conheça o que podemos fazer por você:</p>

    <div class="row" *ngIf="width >= 992">
      <div
        class="card col"
        *ngFor="let card of cards; let i = index"
      >
        <img src="{{ card.image }}" alt="icone" class="img-fluid" />

        <h5>{{ card.title }}</h5>

        <p>{{ card.text }}</p>
      </div>
    </div>
    <div class="row" *ngIf="width < 992">
      <div class="col">
        <div id="carouselIndicatorCuidado" class="carousel slide" data-ride="carousel">
          <ol class="carousel-indicators">
            <li
              data-target="#carouselIndicatorCuidado"
              data-slide-to="0"
              class="active"
            ></li>
            <li data-target="#carouselIndicatorCuidado" data-slide-to="1"></li>
            <li data-target="#carouselIndicatorCuidado" data-slide-to="2"></li>
            <li data-target="#carouselIndicatorCuidado" data-slide-to="3"></li>
          </ol>
          <div class="carousel-inner">
            <div
              class="carousel-item"
              *ngFor="let card of cards; let isFirst = first"
              [ngClass]="{ active: isFirst }"
            >
              <div class="card">
                <img src="{{ card.image }}" alt="icone" class="img-fluid" />

                <h5>{{ card.title }}</h5>

                <p>{{ card.text }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
