import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-como-funciona',
  templateUrl: './como-funciona.component.html',
  styleUrls: ['./como-funciona.component.scss'],
})
export class ComoFuncionaComponent implements OnInit {
  etapas = [
    {
      stage: '1',
      title: 'Monitoramento pela pulseira',
      text:
        'Você recebe acesso ao aplicativo CINCO e uma pulseira inteligente, previamente programada para coletar seus sinais vitais automaticamente.',
      image: 'assets/images/ilustracoes/como-funciona-1.svg',
    },
    {
      stage: '2',
      title: 'Comunicação com aplicativos',
      text:
        'Os dados são exibidos no seu app e transmitidos para a nossa central de saúde, onde os nossos profissionais de saúde sempre o acompanham',
      image: 'assets/images/ilustracoes/como-funciona-2.svg',
    },
    {
      stage: '3',
      title: 'Cuidado humano online',
      text:
        'Nossa equipe de saúde o acompanha remotamente, por meio de protocolos personalizados e sempre que houver alguma necessidade extra',
      image: 'assets/images/ilustracoes/como-funciona-3.svg',
    },
  ];
  height: number;
  width: number;

  constructor() {}

  ngOnInit(): void {
    this.detectScreenSize();
  }

  @HostListener('window:resize', [])
  public onResize() {
    this.detectScreenSize();
  }

  private detectScreenSize() {
    this.height = window.innerHeight;
    this.width = window.innerWidth;
  }
}
