<header id="header" class="header">
  <div
    class="container-fluid fixed-top {{ type }}"
    (scroll)="onWindowScroll($event)"
  >
    <div class="container-lg">
      <div class="logo">
        <a href="#header" class="page-scroll">
          <img
            id="logo-header"
            src="assets/images/logo-cinco.svg"
            alt="logo cinco"
          />
        </a>
      </div>
      <div class="menu">
        <ul>
          <li *ngFor="let link of links">
            <a href="{{ link.href }}" class="page-scroll">{{ link.texto }}</a>
          </li>
        </ul>
      </div>
      <div class="toggle">
        <svg
          (click)="toggleSidebar()"
          width="31"
          height="20"
          viewBox="0 0 31 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0 1L31 1" stroke-width="2" />
          <path d="M0 10.4721H30.9996" stroke-width="2" />
          <path d="M13 19L31 19" stroke-width="2" />
        </svg>
      </div>
    </div>
    <ng-sidebar-container>
      <ng-sidebar [showBackdrop]="true" position="right" [(opened)]="opened">
        <div class="botao-fechar">
          <a (click)="toggleSidebar()"
            ><img src="assets/images/icones/botao-fechar.svg"
          /></a>
        </div>
        <div class="logo-toggle">
          <a href="inicio"
            ><img src="../../../assets/images/logo-cinco-3.svg" alt=""
          /></a>
        </div>
        <div class="links" *ngFor="let link of links">
          <a
            (click)="toggleSidebar()"
            href="{{ link.href }}"
            class="page-scroll"
            >{{ link.texto }}</a
          >
        </div>
        <div class="register-button register-sidebar-button">
          <a
            (click)="toggleSidebar()"
            class="btn-outline-lg btn-outline-lg-white page-scroll"
            href="#planos"
            rel="noopener noreferrer"
            >Assine</a
          >
        </div>
      </ng-sidebar>
    </ng-sidebar-container>
  </div>
</header>
