import { Component, HostListener, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment.prod';

interface CardProps <S extends string = string > {
  key: 'monthly' | 'semestral' | 'enterprise';
  topBadge?: S;
  title: S;
  benefits: S[];
  originalPrice?: S;
  priceWithDiscount?: S;
  tip: S;
  whatsappMessageToSend?: S;
  selected: boolean;
}

@Component({
  selector: 'app-planos',
  templateUrl: './planos.component.html',
  styleUrls: ['./planos.component.scss'],
})
export class PlanosComponent implements OnInit {
  public cards: CardProps[] = [];

  public selectedCard: CardProps;

  public whatsappContactPhone: string;

  height: number;
  width: number;

  constructor() {
    this.whatsappContactPhone =
      environment.whatsappContactPhone;

    this.cards.push({
      key: 'monthly',
      title: 'Mensal',
      tip: 'Cancele quando quiser',
      topBadge: '-53%',
      benefits:
        [
          'Pulseira inteligente',
          'Teleorientação com enfermeiros por Whatsapp, vídeo ou ligação',
          'Cancele quando quiser'
        ],
      originalPrice: '129,00',
      priceWithDiscount: '79,00',
      whatsappMessageToSend: 'Ol%C3%A1,%20gostaria%20de%20assinar%20o%20plano%20mensal%20do%20Sa%C3%BAde%20Cinco',
      selected: true,
    });

    this.cards.push({
      key: 'semestral',
      title: 'Semestral',
      tip: 'Sem taxa de adesão',
      benefits: [
        'Pulseira inteligente',
        'Teleorientação com enfermeiros por Whatsapp, vídeo ou ligação',
        'Sem taxa de adesão'
      ],
      topBadge: 'Melhor oferta!',
      originalPrice: '119,00',
      priceWithDiscount: '69,00',
      whatsappMessageToSend: 'Ol%C3%A1,%20gostaria%20de%20assinar%20o%20plano%20semestral%20do%20Sa%C3%BAde%20Cinco',
      selected: false,
    });

    this.cards.push({
      key: 'enterprise',
      title: 'Empresas',
      benefits: [
        'Todos os benefícios dos planos da pessoa física',
        'Acesso ao sistema de gestão das informações',
        'Relatórios e dashboards de acompanhamento estratégicos'
      ],
      tip: 'Solicite o nosso material para empresas',
      selected: false,
    });

    this.selectedCard = this.cards[0];
  }

  ngOnInit(): void {
    this.detectScreenSize();
  }

  public selectCard(key: CardProps['key']) {
   const newSelectedCard = this.cards.find(card => card.key === key);

   newSelectedCard.selected = true;

   this.cards.filter(card => card.key !== key).forEach(card => card.selected = false);

   this.selectedCard = newSelectedCard;
  }


  @HostListener('window:resize', [])
  public onResize() {
    this.detectScreenSize();
  }

  private detectScreenSize() {
    this.height = window.innerHeight;
    this.width = window.innerWidth;
  }
}
