<section class="conheca" id="conheca">
  <div class="container">
    <div class="row">
      <div class="coluna-1 col-sm-12 col-md-6">
        <img
        src="assets/images/parametros-cinco.svg"
        class="img-fluid"
        alt="pulseira"
        />
      </div>
      <div class="coluna-2 col-sm-12 col-md-6 align-middle">
        <h6>Conheça</h6>
        
        <h2>Quanto vale o seu bem-estar?</h2>
        
        <p>
          Muito além de um serviço de saude preventiva, a CINCO é uma combinação perfeita de tecnologia cuidado humano para que você possa ter mais qualidade de vida, independente da sua condição.
        </p>
        
        <p>Fácil de usar e não invasivo, a CINCO foi projetada para se adaptar à rotina das pessoas. É só vestir a pulseira que nós cuidamos do restante!</p>
        
        <p>Porque o verdadeiro valor está em aproveitar cada momento da vida com mais qualidade.</p>
          </div>
        </div>
      </div>
    </section>
    