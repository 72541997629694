import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-cuidado',
  templateUrl: './cuidado.component.html',
  styleUrls: ['./cuidado.component.scss'],
})
export class CuidadoComponent implements OnInit {
  cards = [
    {
      title: 'Múltiplos sinais monitorados com carinho',
      text:
        'Além da tecnologia dos sensores utilizados para monitorar seus sinais vitais, usamos o telemonitoramento humanizado',
      image: 'assets/images/ilustracoes/cuidado-1.svg',
    },
    {
      title: 'Estímulo na adoção de novos hábitos',
      text:
        'Beber mais água, caminhar mais, alimentar-se melhor. Seja qual for o novo hábito, sendo saudável, nós te ajudamos a construi-lo',
      image: 'assets/images/ilustracoes/cuidado-2.svg',
    },
    {
      title: 'Teleorientação de saúde humanizada',
      text:
        'Especialistas monitoram sinais e contactam o paciente por chamadas de vídeo ou ligação sempre que necessário',
      image: 'assets/images/ilustracoes/cuidado-3.svg',
    },
    // {
    //   title: 'Assinatura simples e totalmente online',
    //   text:
    //     'Prezamos por uma comunicação simples e prática para resolver qualquer situação que você precisar, a qualquer momento.',
    //   image: 'assets/images/ilustracoes/cuidado-4.svg',
    // },
  ];
  height: number;
  width: number;

  constructor() {}

  ngOnInit(): void {
    this.detectScreenSize();
  }

  @HostListener('window:resize', [])
  public onResize() {
    this.detectScreenSize();
  }

  private detectScreenSize() {
    this.height = window.innerHeight;
    this.width = window.innerWidth;
  }
}
